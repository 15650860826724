<template>
    <div class="comment-wrap">
        <el-row>
            <el-col v-for="(m, index) in list" class="list">
                <div class="user">
                    <img :src="rImg(m.head_portrait)" :onerror="txImg" class="tx" alt="" />
                    <div class="name-box">
                        <span class="name">{{ m.name}}</span>
                        <p class="time">{{ m.addtime }}</p>
                    </div>
                </div>
                <div class="content" v-html="dealPing(m)"></div>
                <ul class="more-msg" v-if="m.childs && m.childs.length>0" @click.stop>
                    <li class="m-li" v-for="(c, ci) in m.childs" v-html="dealPing(c, 1)"></li>
                    <p class="all-msg" v-if="m.nextcount > m.childs.length" @click.stop="openApp()">查看全部{{m.nextcount}}条回复</p>
                </ul>
            </el-col>
        </el-row>
    </div>

</template>
<script>
    export default {
        name: "pList",
        components: {},
        props: ["list", "cls"],
        data() {
            return {
                loading: false,
            };
        },
        created() {},
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            dealPing(m, type) {
                let p = m.content;
                if (this.cls == 4 && !m.paystate && m.price) {
                    if (p.length > 20) {
                        p = p.substr(0, 20) + `....<span class="ping-price">支付${m.price}金币查看答案全文</span>`;
                    }
                } else {
                    p = this.ping(m, type)
                }
                return p;
            },
            ping(m, type) {
                let c = m.content;
                if (!c) return c;
                if (c.indexOf('&#x40;') != -1 && m.at && m.at.length) {
                    // @使用css补充，避免重复匹配，如内容为 @张三四，@张三，重复匹配会导致张三标签嵌入张三四内
                    m.at.forEach(function(v, i) {
                        let t = '&#x40;' + v.name;
                        c = c.replace(t, `<span class="link-at">${v.name}</span>`);
                    });
                }
                if (m.touser) {
                    c = `<span class="msg-name">回复&nbsp;${m.touser.name}</span>` + c;
                }
                if (type) {
                    c = `<span class="msg-name">${m.name}:</span>` + c
                }
                return c;
            }
        },
        computed: {},
        watch: {
            // 监听
        },
    };
</script>

<style lang="less" scoped>
    .list {
        padding: 10px 0;
        border-bottom: 1px solid #eee;
    }

    .user {
        display: flex;

        .tx {
            width: 40px;
            height: 40px;
        }

        .name-box {
            margin-left: 10px;

            .time {
                margin-top: 5px;
                font-size: 12px;
                color: #ccc;
            }
        }
    }

    .content {
        margin-top: 10px;
        margin-left: 50px;
    }

    ul,
    li {
        list-style: none;
    }

    /* 回复 */
    .more-msg {
        background: #f5f5f5;
        border-radius: 7px;
        padding: 10px;
        margin-top: 10px;

        .m-li {
            margin-bottom: 7px;
            word-break: break-all;
            font-size: 0.6rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .all-msg {
            color: #49C265;
            margin-top: 7px;
            cursor: pointer;
        }
    }
</style>
<style>
    .ping-price {
        color: #ff9800;
        font-size: 0.6rem;
    }

    .link-at {
        color: #49C265;
        cursor: pointer;
    }

    .link-at::before {
        display: inline-block;
        content: '@';
        color: #49C265;
    }

    .comment-wrap .msg-name {
        color: #999;
        margin-right: 5px;
    }

    .comment-wrap .link {
        color: #49C265;
    }
</style>